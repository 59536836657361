import { render, staticRenderFns } from "./BookingCustomerSimplified.vue?vue&type=template&id=546c6475&scoped=true"
import script from "./BookingCustomerSimplified.vue?vue&type=script&lang=js"
export * from "./BookingCustomerSimplified.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "546c6475",
  null
  
)

export default component.exports